/********** Template CSS **********/
@import url("https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Lobster&family=Source+Code+Pro:ital,wght@1,300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tiro+Bangla&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

:root {
  --primary: #24695C;
  --primary2: #045f4a;

  --light: #f1f8ff;
  --dark: #0f172b;
}

@keyframes fade-in-up {
  0% {
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 4px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(133, 133, 133, 0.4) !important;
  border-radius: 20px !important;
}

.main-app-logo {
  background-color: #24695C;
  padding: 3px 7px !important;
  color: #ffff !important;
  border-radius: 5px !important;

  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
  letter-spacing: 2px !important;
}

.video-snack {
  background-color: blue !important;
}

.video iframe {
  max-width: 100%;
  max-height: 100%;
}

.video.stuck {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 260px;
  height: 145px;
  transform: translateY(100%);
  animation: fade-in-up 0.75s ease forwards;
  z-index: 1;
}

.page-initial {
  height: 100vh;
  background-color: #ccf1db;
}

.disabled-a {
  pointer-events: none;
  cursor: default;
}

.table-head {
  background-color: rgba(228, 248, 234, 0.9) !important;
}

.accordion-title:hover {
  background-color: rgba(255, 166, 0, 0.3) !important;
}

.accordion-back {
  background-color: rgba(255, 166, 0, 0.3) !important;
}

/* .log-row:hover {
  background-color: rgba(255, 166, 0, 0.2) !important;
} */

.avatar-style {
  width: 39px !important;
  height: 39px !important;
}

.sub-link:hover {
  color: #FFFF !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.link-mapa {
  color: inherit !important;
}

.border-user {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.link-mapa:hover {
  color: #20ac83 !important;
}

.avatar-style1 {
  width: 39px !important;
  height: 39px !important;
}

.list-item:hover {
  color: #fff !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.detalhes-menu {
  border-radius: 7px !important;
  width: 100px !important;
}

.detalhes-menu1 {
  border-radius: 7px !important;
  width: 120px !important;
}

.div-detalhes {
  max-height: 320px !important;
  overflow: auto !important;
}

.actions-button:hover {
  background-color: rgba(128, 128, 128, 0.1) !important;
}

.link-dashboard:hover {
  color: #24695C !important;
  text-decoration: underline !important;
}

/* .actions-button:active {
  color: white !important;
  background-color: #ea8b0f !important;
} */

.accordion-buttons:hover {
  background-color: rgba(128, 128, 128, 0.15) !important;
}

.button-users:hover {
  box-shadow: 1px 4px 4px gray !important;
}

.close-modal:active {
  color: white !important;
  background-color: #ea8b0f !important;
}

.close-modal:hover {
  color: white !important;
  background-color: #ea8b0f !important;
}

.divider {
  width: 100%;
  margin: 20px 0px 0px 0px;
  height: 1px;
  background-color: rgba(234, 139, 15, 0.3);
}

.divider2 {
  width: 100%;
  margin: 20px 0px 0px 0px;
  height: 0.7px;
  background-color: rgba(163, 161, 159, 0.3);
}

.divider5 {
  width: 70%;
  height: 0.7px;
  background-color: rgba(163, 161, 159, 0.3);
}

.divider4 {
  width: 100%;
  margin: 20px 0px 0px 0px;
  height: 0.7px;
  background-color: rgba(255, 193, 7);
}

.divider3 {
  width: 95%;
  margin: 0px 0px 7px 0px;
  height: 0.7px;
  background-color: rgba(0, 0, 0, 0.12);
}

.appbar-admin {
  background-color: var(--dark) !important;
}

.link-login:hover {
  color: #24695C !important;
}

.bt-dark {
  background-color: #c4710e !important;
}

.bt-card {
  font-size: small !important;
}

.bt-card:hover {
  color: #20ac83 !important;
}

.ver-mais:hover {
  background-color: rgba(128, 128, 128, 0.4);
}

.ver-mais {
  background-color: rgba(15, 23, 43, 0.5);
  border: solid 2px #3fd9a4 !important;
  border-radius: 7px !important;
}

.card-servicos:hover {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
}

.card-servicos {
  height: 370px !important;
  width: 300px !important;
  background-color: #ffffff;
}

.texto-servicos {
  height: 110px;
}

.card-head {
  height: 120px !important;
}

.history-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: -10px;

}

.history-desc1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}

.service-titles {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}

.d-longa {
  height: 100px !important;
}

.d-longa-services {
  height: 90px !important;
}

.select-services {
  background-color: #24695C !important;
  color: white !important;
  border: none !important;
  width: 240px !important;
}

.select-services option:hover {
  background-color: #10ca2f !important;
}

/* .ff-secondary {
  font-family: "Pacifico", cursive;
} */
.ff-secondary {
  font-family: "Courier New", Courier, monospace, cursive;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 10px;
  bottom: 45px;
  z-index: 99;
}

.back-to-top2 {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
  margin-top: 9px;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  text-transform: initial !important;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.navbar-toggler.btn-primary {
  color: #24695C !important;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-lg-square:hover {
  color: var(--primary) !important;
  background-color: var(--light) !important;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}

/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 15px 0;
  font-size: 17px;
  color: #24695C !important;
  font-weight: 500;
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 0;
  color: #24695C !important;
}

.log-btn:hover {
  background-color: rgb(235, 235, 235) !important;
  color: #24695C !important;
}

.log-btn {
  background-color: var(--light) !important;
  color: #24695C !important;
}

.sticky-top.navbar-dark .log-btn {
  color: #24695C !important;
  background-color: var(--light) !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
  transform: scale(1.3);
}

.navbar-dark .navbar-nav .nav-link.active {
  text-decoration: underline;
}

.navbar-dark .navbar-brand img {
  max-height: 60px;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-brand img {
  max-height: 45px;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-dark {
    position: relative;
  }

  .navbar-dark {
    background-color: #f1f8ff !important;
  }

  .navbar-dark .navbar-collapse {
    margin-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .navbar-dark .navbar-nav .nav-link,
  .sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
    color: #24695C !important;
  }

  .log-btn {
    color: #ffffff !important;
    background-color: #24695C !important;
  }

  .navbar-dark .navbar-brand img {
    max-height: 45px;
  }

  .paper,
  .tabCasos {
    display: none;
  }

  .search-div {
    margin-top: -30px !important;
    margin-bottom: 20px !important;
  }

  .button-details {
    width: 140px !important;
  }

  .avatar-detalhes {
    height: 250px !important;
  }
}

@media (min-width: 992px) {
  .navbar-dark {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgb(255, 255, 255);
  }

  .sticky-top.navbar-dark {
    position: fixed;
    background: rgb(255, 255, 255);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
  }

  .paper1,
  .tabCasos1 {
    display: none;
  }

  .avatar-detalhes {
    height: 370px !important;
  }
}

@media (min-width: 768px) {
  .button-details {
    width: 150px !important;
  }
}

@media (max-width: 768px) {
  .tab-casos {
    width: 50% !important;
  }

  .avatar-detalhes {
    height: 370px !important;
  }
}

@media (max-width: 420px) {
  .avatar-detalhes {
    height: 250px !important;
  }
}

@media (max-width: 360px) {
  .avatar-detalhes {
    height: 200px !important;
  }
}

@media (min-width: 421px) and (max-width: 491px) {
  .avatar-detalhes {
    height: 270px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .paper1 {
    display: none !important;
  }

  .paper {
    display: block !important;
  }

  .search-input {
    max-width: 200px !important;
  }
}

@media (min-width: 991px) and (max-width: 1060px) {
  .academia-logo {
    width: 210px !important;
  }
}

.paper-users {
  height: 350px !important;
}

/* .paper,
.paper1 {
  box-shadow: 4px 10px 4px var(--primary) !important;
} */
/*** Hero Header ***/
.hero-header {
  background: rgb(255, 255, 255);
  background: linear-gradient(175deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 30%,
      rgba(255, 255, 255, 1) 39%,
      rgba(255, 255, 255, 0.85) 48%,
      rgba(36, 105, 92, 0.85) 91%);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -50px;
  min-height: 100vh !important;
}

.hero-header-denuncias {
  background: linear-gradient(rgba(36, 105, 92, 1), rgba(0, 0, 0, 0.8)),
    url(../img/kamba/blindgirl.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}

.hero-header-encaminhadas {
  background: linear-gradient(rgba(15, 23, 43, 0.5), rgba(15, 23, 43, 0.5)),
    url(../img/kamba/mgcas.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -100px;
}

.hero-header-detalhes {
  /*   background: linear-gradient(rgba(15, 23, 43, 0.9), rgba(15, 23, 43, 0.9)),
    url(../img/violencia2.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; */
  margin-top: -100px;
  height: 250px;
}

.hero-img {
  height: 100%;
  border-radius: 50%;
  background-color: white !important;
}

/* .hero-header img {
  transform: rotate(-15deg) !important;
} */

/* @keyframes imgRotate {
  100% {
    transform: rotate(360deg);
  }
} */

.breadcrumb-item+.breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

/*** Section Title ***/
.section-title {
  position: relative;
  display: inline-block;
}

.section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: #FFA500;
}

.section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: #FFA500;
}

.section-title.text-start::before,
.section-title.text-end::after {
  display: none;
}

/*** Service ***/
.service-item {
  box-shadow: 2px 0 7px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.service-item:hover {}

.service-item * {
  transition: 0.5s;
}

.service-item:hover * {}

/*** Food Menu ***/
.nav-pills .nav-item .active {
  border-bottom: 2px solid var(--primary);
}

.tab-casos:hover {
  background-color: rgba(128, 128, 128, 0.09);
}

/* .images {
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
} */
/*** Youtube Video ***/
/*
.video .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

 .video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
} */

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

/* #videoModal {
  z-index: 99999;
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
} */

/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  height: calc(100% - 38px);
  transition: 0.5s;
}

.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item:hover {
  height: 100%;
}

.team-item .btn {
  border-radius: 38px 38px 0 0;
}

/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: 0.5s;
}

/* .testimonial-item:hover {
  background-color: rgba(128, 128, 128, 0.09) !important;
} */

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: var(--light) !important;
}

.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--primary);
  border-color: var(--primary);
}

.detalhes:hover {
  text-decoration: underline;
}

.detalhes2 {
  text-decoration: underline;
  cursor: pointer;
}

.detalhes2:hover {
  color: var(--primary) !important;
}

/* .listagem {
  background-color: rgba(254, 161, 22, 0.07);
} */

.btn-search:hover {
  background-color: #07b68d !important;
}

.back-notfound {
  text-decoration: underline;
  cursor: pointer;
}

.back-notfound:hover {
  color: #3f51b5 !important;
}

/*** Footer ***/

.footer .btn.btn-social {
  margin-right: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 35px;
  transition: 0.3s;
  margin: 7px !important;
}

.footer .btn.btn-social:hover {
  transform: scale(1.1) !important;
  cursor: pointer !important;
}

.insta-btn {
  background: radial-gradient(circle at 33% 100%,
      #fed373 4%,
      #f15245 30%,
      #d92e7f 62%,
      #9b36b7 85%,
      #515ecf);
}

.whats-btn {
  background-color: #25d366 !important;
}

.fb-btn {
  background-color: #3b5998 !important;
}

.twitter-btn {
  background-color: #00acee !important;
}

.youtube-btn {
  background-color: #ff0000 !important;
}

.linkedin-btn {
  background-color: #0e76a8 !important;
}

.mail-to:hover {
  text-decoration: underline !important;
  color: #24695C;
}

.footerMenu:hover {
  color: var(--primary) !important;
  cursor: pointer !important;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 7px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer-copyright {
  background-color: rgba(128, 128, 128, 0.3) !important;
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

body {
  background-color: rgba(15, 23, 43, 0.005) !important;
}

@media only screen and (max-width: 600px) {
  .map {
    width: 60% !important;
    /* margin-top: 90px !important; */
  }
}

/* ----------------SIDEBAR CSS-------------------------- */

.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 320px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.left .modal-body,
.modal.right .modal-body {
  padding: 15px 15px 80px;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

/* ----- MODAL STYLE ----- */
.modal-content {
  border-radius: 0;
  border: none;
}

.modal-header {
  border-bottom-color: #eeeeee;
  background-color: #fafafa;
}

.label-details {
  font-size: small;
  color: #3f51b5;
  font: bold;
}

.links {
  color: #3f51b5 !important;
}

.links:hover {
  text-decoration: underline !important;
}

/* ----- v CAN BE DELETED v ----- */
body {
  background-color: #78909c;
}

.demo {
  padding-top: 60px;
  padding-bottom: 110px;
}

.btn-demo {
  margin: 15px;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 16px;
  background-color: #ffffff;
}

.btn-demo:focus {
  outline: 0;
}

.footer {
  padding-bottom: 0 !important;
  background-color: #eff2f6 !important;
}

.demo-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #921616;
  text-align: center;
}

.demo-footer>a {
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.centerr {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* ------------------CAROUSEL HOME-------------------------------- */

.imaged {
  height: auto;
  border-radius: 10px;
}

.imaged.w16 {
  width: 16px !important;
}

.imaged.w24 {
  width: 24px !important;
}

.imaged.w32 {
  width: 32px !important;
}

.imaged.w36 {
  width: 36px !important;
}

.imaged.w48 {
  width: 48px !important;
}

.imaged.w64 {
  width: 64px !important;
}

.imaged.w76 {
  width: 76px !important;
}

.imaged.w86 {
  width: 86px !important;
}

.imaged.w100 {
  width: 100px !important;
}

.imaged.w120 {
  width: 120px !important;
}

.imaged.w140 {
  width: 140px !important;
}

.imaged.w160 {
  width: 160px !important;
}

.imaged.w180 {
  width: 180px !important;
}

.imaged.w200 {
  width: 200px !important;
}

.imaged.img-fluid {
  width: 100%;
}

.imaged.rounded {
  border-radius: 100% !important;
}

.imaged.radius {
  border-radius: 10px !important;
}

.imaged.square {
  border-radius: 0 !important;
}

.carousel-single .splide__slide {
  margin-left: -16px !important;
  padding-right: 16px !important;
}

.carousel-multiple .splide__slide {
  margin-left: -8px !important;
  padding-right: 8px !important;
}

.carousel-small .splide__slide {
  margin-left: -8px !important;
  padding-right: 0px !important;
}

.carousel-small .splide__slide:first-child {
  margin-left: 24px !important;
}

.blog-card {
  padding: 0;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
}

.blog-card img {
  border-radius: 10px 10px 0 0;
  height: 100px;
}

.blog-card .text {
  padding: 10px 10px;
}

.blog-card .title {
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.4em;
  height: 67px;
  overflow: hidden;
}

.blog-card small {
  font-size: 11px;
  color: #a9abad;
  margin: 0;
  line-height: 1.2em;
}

.profile-card {
  background-color: rgba(255, 166, 0, 0.05) !important;
}

/* ----STYLE TO INPUT FILE-------*/
.upload-photo {
  display: none !important;
}

.label1 {
  background-color: rgb(255, 255, 255);
  color: var(--dark);
  padding: 0.2rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  border-width: 1px;
  border-color: var(--primary);
  font-style: inherit !important;
  border-style: groove;
  cursor: pointer;
  margin-top: 1rem;
  height: 40px !important;
}

.label1:hover {
  color: var(--primary);
}

#file-chosen {
  margin-left: 0.3rem;
  font-family: sans-serif;
}

.texto-history {
  border: solid 0.5px #20ac83 !important;
  border-radius: 5px !important;
  margin: 20px !important;
}

.texto-hist {
  font-family: "Source Code Pro", monospace;
  font-size: 1.3em !important;
}

.text-mains {
  font-family: "Fjalla One", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: 2px !important;
}

.bold-text {
  font-weight: 700 !important;
  border-bottom: 7px solid #FEB400;
  padding-bottom: 5px !important;
}

.advanced {
  text-decoration: none;
  font-size: 15px;
  font-weight: 500;
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary:active {
  color: #fff;
  background-color: #00838f !important;
  border-color: #00838f !important;
  box-shadow: none;
}

.advanced {
  color: #00838f !important;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #00838f;
}

.btn-service {
  border-radius: 15px !important;
}

/* -----------------COMMENTS----------------------- */

.comment {
  background-color: #ffffff;
  float: left;
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 10px;
}

.text-comment:hover {
  cursor: pointer !important;
}

.comment h6,
.comment span,
.darker h4,
.darker span {
  display: inline;
}

.comment p,
.comment span,
.darker p,
.darker span {
  color: grey;
}

#darker img {
  margin-right: 15px;
  position: static;
}



.box {
  position: absolute !important;
  cursor: move;
  color: black;
  max-width: 215px;
  border-radius: 5px;
  padding: 1em;
  margin: auto;
  user-select: none;
  bottom: 50px !important;
  right: 50px !important;
  background-color: #24695C !important;
}




.steper-button {
  background-color: #FB7900 !important;
  margin-top: 10px !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
  color: white !important;
}

.steper-button-ant {
  margin-top: 10px !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
  color: #FB7900 !important;
  height: 37px;
}

.steper-button-cont {
  margin-right: 10px !important;
  text-transform: capitalize !important;
  color: #FB7900 !important;
}

.audio-button-reset {
  text-transform: capitalize !important;
  color: red !important;
  height: 37px;
  background-color: #F5F5F5 !important;
}

.steper-button-ant:disabled {
  margin-top: 10px !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
  color: gray !important;
  background-color: #F4F4F4;
}

.steper-button:disabled {
  margin-top: 10px !important;
  margin-right: 10px !important;
  text-transform: capitalize !important;
  color: #6E6E6E !important;
  background-color: #D2D2D2 !important;
}


.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #E1E4E8 !important;
}


.avatar {
  width: 45px !important;
  height: 45px !important;
  border: 2px solid #FFFF
}

.more-card {
  color: #0e76a8 !important;
  cursor: pointer;
}

.more-card:hover {
  text-decoration: underline;
}

.loader {
  margin: auto;
  border: 8px solid #EAF0F6;
  border-radius: 50%;
  border-top: 5px solid #FB7900;
  width: 50px;
  height: 50px;
  animation: spinner 1s linear infinite;
}


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.form-section-icon {
  color: #FFFF;
  background-color: #FB7900;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.urgent-badge {
  padding: 5px 15px;
  justify-content: center;
  align-items: flex-end;
  border-radius: 24px;
  background: #FF5F5F;
  font-family: var(--primary-font-family);
  color: #FFFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: dashed !important;
}

.images-listing {
  max-height: 500px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
}


.div-criativa {
  padding: 20px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.div-criative {
  border: 5px solid #FFA500;
  background-color: #FFFF;
  border-radius: 10px;
  padding: 5px 20px;
  min-width: 300px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-family: "Helvetica Neue", Arial, sans-serif;
}

.div-criative2 {
  padding: 5px;
  background-color: #FFA500;
  border-radius: 10px;
}

.btn_1 {
  background-image: linear-gradient(to left, #258641 0%, #4AD294 51%, #258641 100%);

}

.btn_1 {
  display: inline-block;
  padding: 12px 21px;
  border-radius: 50px;
  font-size: 14px;
  color: #fff;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
  text-transform: capitalize;
  background-size: 200% auto;
  border: 1px solid transparent;
  box-shadow: 0px 12px 20px 0px rgba(255, 126, 95, 0.15);
}

/* line 38, E:/172 Etrain Education/172_Etrain_Education_html/sass/_button.scss */
.btn_1:hover {
  color: #fff !important;
  background-position: right center;
  box-shadow: 0px 10px 30px 0px rgba(193, 34, 10, 0.2);
}

/* Estilo do texto em itálico */
.it-alic {
  font-style: italic;
  font-size: 18px;
}

.swicth-view-label {
  display: flex;
  align-items: center;
}

.icon-button-search:hover {
  background-color: #CDCECE !important;
}

.swicth-view-label:hover {
  cursor: pointer;
  color: #3f50b5;
}

.active-switch-view-label {
  color: #3f50b5;
  border-bottom: 2px solid #3f50b5;
}

.table-row-simple:hover {
  background-color: #F7F7F7 !important;
}

.table-row-red:hover {
  background-color: #FEEDED !important;
}

._1dpop {
  pointer-events: none;
}