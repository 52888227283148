.complaint-recorder {
  font-family: sans-serif;
  text-align: center;
}

._1lB9c {
  background-color: white !important;
  border: 1px solid rgb(0, 0, 0, 0.1) !important;
}

._3bC73 {
  padding-top: 30px !important;
  background-color: #24695C !important;
}

._1ceqH ._3bC73 ._1YOWG {
  position: relative !important;
  left: 50% !important;
  transform: translate(-50%);
  bottom: 10px !important;
}

._1dpop {
  position: relative !important;
  margin-top: 20px !important;
}

._37kfa {
  text-indent: -9999px;
  line-height: 0;
  /* Collapse the original line */
}

._37kfa::after {
  content: "Carregar";
  text-indent: 0;
  display: block;
  line-height: initial;
  pointer-events: none !important;
  /* New content takes up original line height */
}

._2gd2_ {
  text-indent: -9999px;
  line-height: 0;
  /* Collapse the original line */
}

._2gd2_::after {
  content: "Limpar";
  text-indent: 0;
  display: block;
  line-height: initial;
  pointer-events: none !important;
  /* New content takes up original line height */
}

.recorder-title {
  color: #FB7900;
}

._eV_dK {
  visibility: hidden !important;
}




/* ==================== AUDIO RECORDER CSS============================== */


.button-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.audio-controls,
.video-controls {
  margin-bottom: 20px;
}

.audio-player,
.video-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-player,
.video-player,
.recorded-player {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.live-player {
  height: 200px;
  width: 400px;
  border: 1px solid #646cff;
  margin-bottom: 30px;
}

.recorded-player video {
  height: 400px;
  width: 800px;
}