.complaint-audio-recorder {
  font-family: sans-serif;
  text-align: center;
  background-color: #ffffff;
  border-top: none !important;
}
.recorder-container {
  width: 350px !important;
  border-bottom: 5px solid #1C7CFF;
}

.complaint-audio-recorder audio{
  border-radius: 50px !important;
}
.flash-background {
  animation: flash 1s infinite alternate;
}

@keyframes flash {
  0% {
    background-color: white;
  }
  50% {
    background-color: #FB7900; /* Primeira cor */
  }
  100% {
    background-color: "#303F9F"; /* Segunda cor */
  }
}