/* Estilos para a div container */
.container-images {
    margin-top: 75px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), #ffffff); /* Gradiente branco */
    border-radius: 10px; /* Cantos arredondados */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sombra básica */
    padding: 10px;
  }
  
  /* Estilos para a div image-container */
  .image-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ffff; /* Linha divisora cinza */
    padding-top: 15px;
  }
  
  /* Estilos para as imagens */
  .image-container img {
    height: auto; /* Altura automática para manter proporções */
    border-radius: 8px; /* Cantos arredondados nas imagens */
    object-fit: cover; /* Garante que todas as imagens tenham o mesmo tamanho */
  }