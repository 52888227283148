.card {
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    border: none;
    /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); */
}

/* ============ Card Table ============ */

.table {
    margin-bottom: 0px;
}

.card .table {
    padding: 8px 15px 2px 15px;
}


.table>:not(caption)>*>* {
    background-color: transparent;
}

/*---------------------------------------------------------------------- /
FONT AWESOME FA CLASS
----------------------------------------------------------------------- */

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.radius-10 {
    border-radius: 10px !important;
}

.card-home {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: .25rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}

.bg-gradient-scooter {
    background: #17ead9;
    background: -webkit-linear-gradient(45deg, rgba(13, 193, 67, 0.7), #24695C) !important;
    background: linear-gradient(45deg, rgba(13, 193, 67, 0.7), #24695C) !important;
}

.bg-gradient-scooter-dashboard {
    background: #17ead9;
    background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
    background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}

.widgets-icons-2 {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    font-size: 27px;
    border-radius: 10px;
}

.rounded-circle {
    border-radius: 50% !important;
}

.bg-gradient-bloody {
    background: #fd3550;
    background: -webkit-linear-gradient(45deg, #fd3550, #f8abab) !important;
    background: linear-gradient(45deg, #fd3550, #f8abab) !important;
}

.bg-gradient-ohhappiness {
    background: #00b09b;
    background: -webkit-linear-gradient(45deg, #00b09b, #96c93d) !important;
    background: linear-gradient(45deg, #00b09b, #96c93d) !important;
}

.bg-gradient-blooker {
    background: #ffdf40;
    background: -webkit-linear-gradient(45deg, #ffdf40, #ff8359) !important;
    background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
}


.entity-card {
    background: #17ead9;
    background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
    background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}

.entity-card .bg-gradient-scooter {
    background: #029FD6;
    background: -webkit-linear-gradient(45deg, #029FD6, #fff) !important;
    background: linear-gradient(45deg, #029FD6, #fff) !important;
}

.entity-card .bg-gradient-scooter .card-iconn {
    color: #FFFFFF !important;
}

.entity-card .main-title {
    color: #FFFFFF !important;
}

.entity-card p {
    color: #FFFFFF !important;
}
